import Loadable from "react-loadable";
import LoaderComponent from "../src/components/LoaderComponent";


const AmbulantPage = Loadable({
    loader: () => import(/* webpackChunkName: 'AmbulantPage' */"../src/pages/ambulant/AmbulantPage"),
    loading: () => LoaderComponent
});
const SatzungPage = Loadable({
    loader: () => import(/* webpackChunkName: 'SatzungPage' */"../src/pages/satzung/SatzungPage"),
    loading: () => LoaderComponent
});
const StiftungPage = Loadable({
    loader: () => import(/* webpackChunkName: 'StiftungPage' */"../src/pages/stiftung/StiftungPage"),
    loading: () => LoaderComponent
});
const StationaerPage = Loadable({
    loader: () => import(/* webpackChunkName: 'StationaerPage' */"../src/pages/stationär/StationaerPage"),
    loading: () => LoaderComponent
});
const AngebotePage = Loadable({
    loader: () => import(/* webpackChunkName: 'AngebotePage' */"../src/pages/Angebote/AngebotePage"),
    loading: () => LoaderComponent
});
const UserManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'UserManagementPage' */"../src/components/userManagement/UserManagementPage"),
    loading: () => LoaderComponent
});
const Home = Loadable({
    loader: () => import(/* webpackChunkName: 'Home' */"../src/pages/Home"),
    loading: () => LoaderComponent
});
const LoginPage = Loadable({
    loader: () => import(/* webpackChunkName: 'Login' */"../src/pages/login/LoginPage"),
    loading: () => LoaderComponent
});
const RegisterPage = Loadable({
    loader: () => import(/* webpackChunkName: 'Register' */"../src/pages/login/RegisterPage"),
    loading: () => LoaderComponent
});
const Imprint = Loadable({
    loader: () => import(/* webpackChunkName: 'Imprint' */"../src/pages/Imprint"),
    loading: () => LoaderComponent
});
const DataPrivacy = Loadable({
    loader: () => import(/* webpackChunkName: 'DataPrivacy' */"../src/pages/DataPrivacy"),
    loading: () => LoaderComponent
});
const NotFoundPage = Loadable({
    loader: () => import(/* webpackChunkName: 'NotFound' */"../src/pages/not_found/NotFoundPage"),
    loading: () => LoaderComponent
});
const FAQPage = Loadable({
    loader: () => import(/* webpackChunkName: 'FAQPage' */"../src/pages/faq/FAQPage.js"),
    loading: () => LoaderComponent
});
const Contact = Loadable({
    loader: () => import(/* webpackChunkName: 'Contact' */"../src/pages/contact/Contact"),
    loading: () => LoaderComponent
});
const DonatePage = Loadable({
    loader: () => import(/* webpackChunkName: 'DonatePage' */"../src/pages/donate/DonatePage"),
    loading: () => LoaderComponent
});
const FaqManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'FaqManagementPage' */"../src/pages/faq/FaqManagementPage"),
    loading: () => LoaderComponent
});
const AppointmentPage = Loadable({
    loader: () => import(/* webpackChunkName: 'AppointmentPage' */"../src/pages/appointment/appointmentPage"),
    loading: () => LoaderComponent
});
const AppointmentManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'AppointmentManagementPage' */"../src/pages/appointment/appointmentManagementPage"),
    loading: () => LoaderComponent
});
const ViewAppointment = Loadable({
    loader: () => import(/* webpackChunkName: 'ViewAppointment' */"../src/pages/appointment/viewAppointment"),
    loading: () => LoaderComponent
});

const AmbulantFAQPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'AmbulantFAQPage' */"../src/pages/ambulant/AmbulantFAQPage"),
    loading: _ => LoaderComponent
});
const StationaerFAQPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'StationaerFAQPage' */"../src/pages/stationär/StationaerFAQPage"),
    loading: _ => LoaderComponent
});
const AGBPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'AGBPage' */"../src/pages/AGBPage"),
    loading: _ => LoaderComponent
});
const JobsPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'JobsPage' */"../src/pages/JobsPage"),
    loading: _ => LoaderComponent
});
const EhrenamtPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'EhrenamtPage' */"../src/pages/EhrenamtPage"),
    loading: _ => LoaderComponent
});

const DownloadPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'DownloadPage' */"../src/pages/DownloadPage"),
    loading: _ => LoaderComponent
});

const CalendulaStiftungPage = Loadable({
    loader: _ => import(/* webpackChunkName: 'CalendulaStiftung' */"../src/pages/calendula_stiftung/CalendulaStiftung.js"),
    loading: _ => LoaderComponent
});

export {
	AmbulantPage,
	StiftungPage,
	StationaerPage,
	AngebotePage,
	UserManagementPage,
	Home,
	LoginPage,
	RegisterPage,
	Imprint,
	DataPrivacy,
	SatzungPage,
	NotFoundPage,
	FAQPage,
	Contact,
	DonatePage,
	FaqManagementPage,
	AppointmentPage,
	AppointmentManagementPage,
	ViewAppointment,
	AmbulantFAQPage,
	StationaerFAQPage,
	AGBPage,
	JobsPage,
	EhrenamtPage,
	DownloadPage,
	CalendulaStiftungPage,
};