import sharedConfig from "./sharedConfig";
import {
	AngebotePage,
	AGBPage,
	AmbulantPage,
	SatzungPage,
	AppointmentManagementPage,
	AppointmentPage,
	Contact,
	DataPrivacy,
	DonatePage,
	EhrenamtPage,
	FaqManagementPage,
	Home,
	Imprint,
	JobsPage,
	LoginPage,
	NotFoundPage,
	RegisterPage,
	StationaerPage,
	StiftungPage,
	UserManagementPage,
	ViewAppointment,
	DownloadPage,
	CalendulaStiftungPage,
} from "./LoadablePages";

let routes = [];
for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
	routes = routes.concat([
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/`,
			exact: true,
			component: Home,
			namespace: "home",
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/404`,
			exact: true,
			component: NotFoundPage,
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/ambulant`,
			exact: true,
			component: AmbulantPage,
			namespace: "ambulant",
			breadCrumbData: [
				{
					title: "titles.ambulant",
					url: "/ambulant",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/calendula-stiftung`,
			exact: true,
			component: CalendulaStiftungPage,
			namespace: "calendula-stiftung",
			breadCrumbData: [
				{
					title: "titles.calendulaStiftung",
					url: "/calendula-stiftung",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/Angebote`,
			exact: true,
			component: AngebotePage,
			namespace: "Angebote",
			breadCrumbData: [
				{
					title: "titles.Angebote",
					url: "/angebote",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/donate`,
			exact: true,
			component: DonatePage,
			namespace: "donate",
			breadCrumbData: [
				{
					title: "titles.donate",
					url: "/donate",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/stiftung`,
			exact: true,
			component: StiftungPage,
			namespace: "stiftung",
			breadCrumbData: [
				{
					title: "titles.stiftung",
					url: "/stiftung",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/stationär`,
			exact: true,
			component: StationaerPage,
			namespace: "stationaer",
			breadCrumbData: [
				{
					title: "titles.stationär",
					url: "/stationär",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/faq-edit`,
			exact: true,
			component: FaqManagementPage,
		},
		// Start: User Management Routes
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/admin/user-management`,
			exact: true,
			component: UserManagementPage,
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/imprint`,
			exact: true,
			component: Imprint,
			breadCrumbData: [
				{
					title: "titles.imprint",
					url: "/imprint",
				},
			],
			namespace: "imprint",
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/termine`,
			exact: true,
			component: AppointmentPage,
			breadCrumbData: [
				{
					title: "titles.appointment",
					url: "/appointment",
				},
			],
			namespace: "termine",
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/admin/termine`,
			exact: true,
			component: AppointmentManagementPage,
			breadCrumbData: [
				{
					title: "titles.appointment",
					url: "/admin/appointment",
				},
			],
			namespace: "termine",
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/satzung`,
			exact: true,
			component: SatzungPage,
			breadCrumbData: [
				{
					title: "satzung",
					url: "/satzung",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/termine/:id`,
			exact: true,
			component: ViewAppointment,
			breadCrumbData: [
				{
					title: "termine",
					url: "/termine",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/downloads`,
			exact: true,
			component: DownloadPage,
			breadCrumbData: [
				{
					title: "Downloads",
					url: "/downloads",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/data-privacy`,
			exact: true,
			component: DataPrivacy,
			breadCrumbData: [
				{
					title: "titles.data_privacy",
					url: "/data-privacy",
				},
			],
			namespace: "dataprivacy",
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/login`,
			exact: true,
			component: LoginPage,
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/register`,
			exact: true,
			component: RegisterPage,
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/ehrenamt`,
			exact: true,
			component: EhrenamtPage,
			breadCrumbData: [
				{
					title: "titles.volunteer",
					url: "/ehrenamt",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/jobs`,
			exact: true,
			component: JobsPage,
			breadCrumbData: [
				{
					title: "titles.jobs",
					url: "/jobs",
				},
			],
		},
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/agb`,
			exact: true,
			component: AGBPage,
			breadCrumbData: [
				{
					title: "titles.agb",
					url: "/agb",
				},
			],
		},
		//Kontakt
		{
			path: `/${sharedConfig.LANGUAGES[i].language}/contact`,
			extract: true,
			component: Contact,
			namespace: "contact",
			breadCrumbData: [
				{
					title: "titles.contact",
					url: "/contact",
				},
			],
		},
		{
			exact: true,
			component: NotFoundPage,
		},
	]);
}

export default routes;
