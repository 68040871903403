import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";
import config from "../../config/main.config"
import {useLocation} from "react-router-dom";
import history from "../../helper/browserHistory";
import {changeColor, MAX_MOBILE_WIDTH, UseWindowSize} from "../../helper/util";


const SideBarItems = [
    {icon: "mail_icon.svg", type: 'contact'},
    {icon: "Icons_Hospiz-Spende-Weiss.svg", type: 'donate'},
    {icon: "Icons_Hospiz_Mitglied-Weiss.svg", type: 'membership'},
];


const SidebarMenu = (sidebarColor, path, i18n) => {
    let applicationWidth = UseWindowSize().width;

    if (applicationWidth <= MAX_MOBILE_WIDTH) {
        return (
            <div className={'mobile-sidebar'} style={{background: sidebarColor}}>
                {SideBarItems.map((icon, index) => (
                    <img onClick={_=>{
                        switch (icon.type) {
                            case 'contact':
                                if (path.includes('stationär')) {
                                    history.push('/' + i18n.language + '/contact#stationaer');
                                } else if (path.includes('ambulant')) {
                                    history.push('/' + i18n.language + '/contact#ambulant');
                                } else {
                                    history.push('/' + i18n.language + '/contact');
                                }
                                break;
                            case 'membership':
                                if(typeof window !== "undefined"){
                                    window.open(config.URL+'downloads/Hospiz_Mitglied_Formular.pdf','_newtab');
                                }
                                break;
                            case 'donate':
                                history.push('/' + i18n.language + '/donate')
                                break;
                            default:
                                break;
                        }
                    }} className={'mobile-sidebar-icon'} src={config.BASE_URL_IMAGES + icon.icon} alt={"icon"}/>
                ))}
            </div>
        )
    } else {
        return SideBarItems.map((icon, index) => (
                <div className={'sidebar-icon-container'} style={{background: sidebarColor}}>
                    <img style={{padding: "10px", width: 'inherit'}} src={config.BASE_URL_IMAGES + icon.icon}
                         alt={"icon"} onClick={_ => {
                        switch (icon.type) {
                            case 'contact':
                                if (path.includes('stationär')) {
                                    history.push('/' + i18n.language + '/contact#stationaer');
                                } else if (path.includes('ambulant')) {
                                    history.push('/' + i18n.language + '/contact#ambulant');
                                } else {
                                    history.push('/' + i18n.language + '/contact');
                                }
                                break;
                            case 'membership':
                                if(typeof window !== "undefined"){
                                    window.open(config.URL+'downloads/Hospiz_Mitglied_Formular.pdf','_newtab');
                                }
                                break;
                            case 'donate':
                                history.push('/' + i18n.language + '/donate')
                                break;
                            default:
                                break;
                        }
                    }}/>
                </div>
            )
        )
    }
}


//TODO: onClick for icons!

const SideBar = (props) => {
    const {i18n} = props;
    const history = useLocation();
    const [sidebarColor, setSidebarColor] = useState('#0063af');
    useEffect(_ => {
        changeColor(history.pathname, setSidebarColor);
    }, [history.pathname])

    return (
        <div className={'side-bar-container'}>
            {SidebarMenu(sidebarColor, history.pathname, i18n)}
        </div>
    )
}

export default withTranslation()(SideBar);