import React, { useEffect, useState } from "react";
import {
	changeColor,
	MAX_MOBILE_WIDTH,
	UseWindowSize,
} from "../../helper/util";
import history from "../../helper/browserHistory";
import { Link, NavLink, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import config from "../../config/main.config";
import { Dropdown } from "semantic-ui-react";
import AdminNavigation from "./adminNavigation";
import UserStore from "../userManagement/userStore";

import { useScrollData } from "scroll-data-hook";

const Navigation = (props) => {
	const { i18n } = props;
	const location = useLocation();
	const applicationWidth = UseWindowSize().width;
	const [showMenu, setShowMenu] = useState(false);
	const [mobileNavbarColor, setMobileNavbarColor] = useState("#0063af");
	const { position } = useScrollData();

	useEffect(
		(_) => {
			if (showMenu && typeof window !== "undefined") {
				document.body.style.overflow = "hidden";
				setTimeout((_) => {}, 5000);
			} else {
				document.body.style.overflow = "auto";
			}
		},
		[showMenu],
	);

	useEffect(
		(_) => {
			changeColor(location.pathname, setMobileNavbarColor);
		},
		[location.pathname],
	);

	return (
		<div className={"grid ui navbar-container"}>
			{UserStore.isAdmin && applicationWidth > MAX_MOBILE_WIDTH ? (
				<AdminNavigation />
			) : null}
			<div
				className={"row centered page-content-wrapper"}
				style={
					showMenu
						? {
								backgroundColor: `${mobileNavbarColor}`,
							}
						: {}
				}
			>
				<div
					className={"navbar-wrapper"}
					style={
						applicationWidth <= MAX_MOBILE_WIDTH
							? { backgroundColor: "transparent" }
							: { backgroundColor: "white" }
					}
				>
					<div
						style={
							applicationWidth <= MAX_MOBILE_WIDTH
								? {
										backgroundColor: `${mobileNavbarColor}`,
										opacity: `${position.y / 200}`,
										width: "100%",
										position: "fixed",
										top: 0,
										left: 0,
										height: "75px",
										zIndex: -1,
									}
								: {}
						}
					/>
					<div
						className={"two wide column"}
						style={{ display: "flex", zIndex: 2 }}
					>
						{applicationWidth <= MAX_MOBILE_WIDTH ? (
							// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
							<img
								className={"nav-image-mobile"}
								src={
									config.BASE_URL_IMAGES +
									(!showMenu ? "/Burger.svg" : "/X.svg")
								}
								onClick={(_) => {
									setShowMenu(!showMenu);
								}}
								alt={"Calendula"}
							/>
						) : null}
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<img
							className={"nav-image"}
							alt={"Calendula"}
							src={
								config.BASE_URL_IMAGES +
								(applicationWidth > MAX_MOBILE_WIDTH
									? "Calendula_Schrift.svg"
									: "Calendula_Schrift_Weiss.svg")
							}
							onClick={(_) => {
								history.push(`/${i18n.language}/`);
							}}
						/>
					</div>
					{applicationWidth >= MAX_MOBILE_WIDTH ? (
						<div className={"navbar-dropdown-container"}>
							<div
								className={"four wide column flex"}
								style={{ margin: "0 auto" }}
							>
								<img
									className={"nav-image-item"}
									src={`${config.BASE_URL_IMAGES}/calendula.webp`}
									alt={"Calendula"}
								/>
								<Dropdown
									className={
										location.pathname.includes("ambulant")
											? "navbar-dropdown-active"
											: "navbar-dropdown"
									}
									text="Hospiz ambulant"
								>
									<Dropdown.Menu
										className={"dropdown-menu"}
										style={{
											background: "#f3ac2b",
											color: "white",
											opacity: "1",
										}}
									>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/ambulant?ambulant-top`}
										>
											Wir besuchen Sie
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/ambulant?ambulant-team`}
										>
											Unser Team
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/ambulant?ambulant-beratung`}
										>
											Beratung und
											<br />
											Pflege
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/ambulant?ambulant-beratung`}
										>
											Kosten
										</Link>
										{/*<Link className={'dropdown-item item'}
                                               to={'/' + i18n.language + '/ambulant/faq'}>Die
                                            gängisten <br/> Fragen</Link>*/}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className={"four wide column flex"}>
								<img
									className={"nav-image-item"}
									src={`${config.BASE_URL_IMAGES}/Hospiz.webp`}
									alt={"Calendula"}
								/>
								<Dropdown
									className={
										location.pathname.includes("stationär")
											? "navbar-dropdown-active"
											: "navbar-dropdown"
									}
									text="Hospiz stationär"
								>
									<Dropdown.Menu
										className={"dropdown-menu"}
										style={{ background: "#0063af" }}
									>
										<Link
											className={"dropdown-item item"}
											to={"stationär?stationaer-top"}
										>
											Über uns
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/stationär?stationaer-pflege`}
										>
											Unsere Pflegeleistungen
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/stationär?stationaer-beratung`}
										>
											Aufnahme und
											<br />
											Beratung{" "}
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/stationär?stationaer-beratung`}
										>
											Kosten
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/stationär?stationaer-team`}
										>
											Das Team
										</Link>
										<Link
											className={"dropdown-item item"}
											to={`/${i18n.language}/stationär?stationaer-raeumlichkeiten`}
										>
											Unsere Räumlichkeiten
										</Link>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className={"three wide column"} style={{ margin: "auto" }}>
								<p
									className={
										location.pathname.includes("calendula-stiftung")
											? "navbar-stiftung-active"
											: "navbar-stiftung"
									}
									style={{ display: "flex", alignItems: "center" }}
								>
									<img
										style={{ marginRight: "5px" }}
										className={"nav-image-item"}
										src={`${config.BASE_URL_IMAGES}/Stiftung.webp`}
										alt={"Calendula Stiftung"}
									/>
									<NavLink
										className={"text"}
										style={{ color: "black" }}
										to={`/${i18n.language}/calendula-stiftung`}
									>
										Calendula Stiftung
									</NavLink>
								</p>
							</div>
							<div className={"three wide column"} style={{ margin: "auto" }}>
								<p
									className={
										location.pathname.includes("termine")
											? "navbar-appointments-active"
											: "navbar-appointments"
									}
								>
									<NavLink
										className={"text"}
										style={{ color: "black" }}
										to={`/${i18n.language}/termine`}
									>
										Veranstaltungen
									</NavLink>
								</p>
							</div>
							<div className={"three wide column"} style={{ margin: "auto" }}>
								<p
									className={
										location.pathname.includes("jobs")
											? "navbar-jobs-active"
											: "navbar-jobs"
									}
								>
									<NavLink
										className={"text"}
										style={{ color: "black" }}
										to={`/${i18n.language}/jobs`}
									>
										Jobs
									</NavLink>
								</p>
							</div>
						</div>
					) : (
						<div style={{ width: "100%" }}>
							<div
								className={showMenu ? "mobile-navbar" : "hide-mobile-navbar"}
							>
								<div className={"nav-item-container-mobile"}>
									<ul className={"nav-mobile-list"}>
										<li className={"nav-mobile-list-header"}>
											<NavLink
												className={"nav-link color-white"}
												onClick={(_) => {
													setShowMenu(false);
												}}
												to={`/${i18n.language}/ambulant?ambulant-top`}
											>
												Hospiz Ambulant
											</NavLink>
											<ul className={"nav-mobile-list nav-sub-list"}>
												<li>
													<NavLink
														to={`/${i18n.language}/ambulant?ambulant-top`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Wir besuchen Sie
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/ambulant?ambulant-team`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Unser Team
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/ambulant?ambulant-beratung`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Beratung und Pflege
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/ambulant?ambulant-beratung`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Kosten
													</NavLink>
												</li>
												{/*<li>
                                                    <NavLink to={`/${i18n.language}/ambulant/faq`} onClick={_ => {
                                                        setShowMenu(false)
                                                    }}
                                                             activeClassName={'nav-link-active'}
                                                             className={'nav-link color-white'}>
                                                        Die gängigsten Fragen
                                                    </NavLink>
                                                </li>*/}
											</ul>
										</li>
										<li className={"nav-mobile-list-header"}>
											<NavLink
												className={"nav-link color-white"}
												onClick={(_) => {
													setShowMenu(false);
												}}
												to={`/${i18n.language}/stationär?stationaer-top`}
											>
												Hospiz Stationär
											</NavLink>
											<ul className={"nav-mobile-list nav-sub-list"}>
												<li>
													<NavLink
														to={`/${i18n.language}/stationär?stationaer-top`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Über uns
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/stationär?stationaer-pflege`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Unsere Pflegeleistungen
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/stationär?stationaer-beratung`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Beratung und Aufnahme
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/stationär?stationaer-beratung`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Kosten
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/stationär?stationaer-team`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Das Team
													</NavLink>
												</li>
												<li>
													<NavLink
														to={`/${i18n.language}/stationär?stationaer-raeumlichkeiten`}
														onClick={(_) => {
															setShowMenu(false);
														}}
														className={"nav-link color-white"}
													>
														Unsere Räumlichkeiten
													</NavLink>
												</li>
												{/*<li>
                                                    <NavLink to={`/${i18n.language}/stationär/faq`} onClick={_ => {
                                                        setShowMenu(false)
                                                    }}
                                                             activeClassName={'nav-link-active'}
                                                             className={'nav-link color-white'}>
                                                        Die gängigsten Fragen
                                                    </NavLink>
                                                </li>*/}
											</ul>
										</li>
										<li className={"nav-mobile-list-header"}>
											<NavLink
												to={`/${i18n.language}/calendula-stiftung`}
												onClick={(_) => {
													setShowMenu(false);
												}}
												className={"nav-link color-white"}
											>
												Calendula Stiftung
											</NavLink>
										</li>{" "}
										<li className={"nav-mobile-list-header"}>
											<NavLink
												to={`/${i18n.language}/termine`}
												onClick={(_) => {
													setShowMenu(false);
												}}
												className={"nav-link color-white"}
											>
												Veranstaltungen
											</NavLink>
										</li>
										<li className={"nav-mobile-list-header"}>
											<NavLink
												to={`/${i18n.language}/jobs`}
												onClick={(_) => {
													setShowMenu(false);
												}}
												className={"nav-link color-white"}
											>
												Jobs
											</NavLink>
										</li>
										<li className={"nav-mobile-list-header"}>
											<NavLink
												to={`/${i18n.language}/imprint`}
												onClick={(_) => {
													setShowMenu(false);
												}}
												className={"nav-link color-white"}
											>
												{i18n.t("nav:titles.imprint")}
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</div>
					)}
					<div className={"two wide column navbar-donate-container"}>
						<button
							type="button"
							className={"nav-bar-button button-1"}
							onClick={() => history.push(`/${i18n.language}/donate`)}
						>
							Spenden und Helfen
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default withTranslation()(Navigation);
